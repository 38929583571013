import URI from 'urijs'
import { config } from '../assets/config/config.js'
import enums from './enums.js'
import i18n from '../i18n.js'

const utils = {}
utils.iso_regions = {
    'BE-VLG': 'Flanders',
    'BE-WAL': 'Wallonia',
    'BE-BRU': 'Brussels',
}

utils.renovation_types = (renovation_names) => {
    // renovation_names: an array of renovation names
    // return an array of unique renovation types matching renovation_names
    const renovations = enums.allRenovations.filter((renovation) =>
        renovation_names.includes(renovation.name)
    )
    return [...new Set(renovations.map((renovation) => renovation.type))]
}

utils.generateValues = (key, selected) => {
    return config
        .find((item) => item.name === key)
        .values.map((item) => ({
            value: item,
            selected: Array.isArray(selected)
                ? selected.includes(item)
                : item === selected,
        }))
}

utils.percentChange = (oldNumber, newNumber) => {
    var decreaseValue = oldNumber - newNumber
    return Math.abs((decreaseValue / oldNumber) * 100)
}

utils.all_langs = (conf) => {
    if (conf.ALL_LANGS && conf.ALL_LANGS.length) {
        return conf.ALL_LANGS
    }
    return ['nl-BE', 'fr-BE', 'en-BE']
}

utils.formatPrice = (x) => {
    return utils.exact_value_formatter(x)
}

utils.clamp = (num, min, max) => Math.min(Math.max(num, min), max)

utils.convertRange = (value, r1, r2) => {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0]
}

utils.capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

utils.user_name = (user) => {
    let name = user
    if (typeof name === 'string') {
        if (name.includes('@')) {
            // Extract name from email
            return name
                .split('@')[0]
                .split('.')
                .map(utils.capitalizeFirstLetter)
                .join(' ')
        } else {
            return name
        }
    } else {
        return name
    }
}

utils.computeLabelFromScore = (score, region) => {
    const epcs = enums.epc_mapping[region]

    let currentEpcLabel

    for (let i = Object.keys(epcs).length - 1; i >= 0; i--) {
        currentEpcLabel = Object.keys(epcs)[i]
        if (score >= epcs[currentEpcLabel]) break
    }

    const color = utils.epc_color(region, currentEpcLabel)

    return {
        label: currentEpcLabel,
        color,
    }
}

utils.epc_color = (region, label) => {
    if (label === '?') return '#798DA6'

    let colors = {
        Wallonia: {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-WAL': {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        Flanders: {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        'BE-VLG': {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        Brussels: {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-BRU': {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
    }

    return colors[region][label]
}

utils.consumptionsData = (unit, region, fuelType, isFuelElectricity = false) => {
    const regionData = { ...enums.consumptions[unit][region] }
    if (isFuelElectricity && fuelType === 'electricity') {
        return {
            min: regionData[fuelType].min + regionData.electricity_heat.min,
            med: regionData[fuelType].med + regionData.electricity_heat.med,
            max: regionData[fuelType].max + regionData.electricity_heat.max,
        }
    } else {
        return regionData[fuelType]
    }
}

utils.epc_percent_mapping = (region, epcScore) => {
    const data = {
        Brussels: {
            epcs: [660, 580, 500, 420, 345, 275, 210, 150, 95, 45, 0],
            percents: [7, 5, 7, 13, 21, 19, 15, 9, 3, 1, 0],
        },
        Flanders: {
            epcs: [1000, 900, 800, 700, 600, 500, 400, 300, 200, 100, 0, -100],
            percents: [3, 2, 3, 5, 8, 13, 14, 18, 20, 13, 0, 0],
        },
        Wallonia: {
            epcs: [900, 800, 700, 600, 510, 425, 340, 255, 170, 86, 65, 0, -20],
            percents: [5, 3, 5, 8, 14, 17, 18, 15, 10, 4, 1, 0, 0],
        },
    }
    if (epcScore !== undefined) {
        const bestEpc = data[region].epcs[data[region].epcs.length - 1]
        if (epcScore < bestEpc) {
            return 0
        }
        let percent = 100
        for (let i = 0; i < data[region].epcs.length - 1; i++) {
            const currentEpcVal = data[region].epcs[i]
            if (epcScore <= currentEpcVal) {
                percent -=
                    data[region].percents[
                        utils.clamp(i + 1, 0, data[region].percents.length - 1)
                    ]
            }
        }
        return utils.clamp(percent, 0, 100)
    } else {
        return data[region]
    }
}

utils.epc_label = (region, epc_value) => {
    if (!region || isNaN(epc_value)) return '?'

    let label = Object.keys(enums.epc_mapping[region])[0]

    Object.keys(enums.epc_mapping[region]).every((key) => {

        if (+epc_value >= enums.epc_mapping[region][key]) {
            label = key
            return true
        } else return false
    })

    return label
}

utils.epc_color = (region, label) => {
    if (!label || label === '?') return '#798DA6'

    let colors = {
        Wallonia: {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-WAL': {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        Flanders: {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        'BE-VLG': {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        Brussels: {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-BRU': {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
    }

    return colors[region][label]
}

utils.value_formatter = new Intl.NumberFormat('fr-BE', {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 3,
}).format

utils.int_value_formatter = (value, lang, maxDigits = 0) => {
    const formater = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: maxDigits,
    }).format
    return formater(value)
}

utils.exact_value_formatter = new Intl.NumberFormat('fr-BE', {
    style: 'currency',
    currency: 'EUR',
}).format

utils.quantile_formatter = (value) => {
    if (value > 0.5) {
        return `top ${Math.ceil((1 - value) * 20) * 5}%`
    } else {
        return `bottom ${Math.ceil(value * 20) * 5}%`
    }
}

utils.isEmptyStr = (str) => {
    return !str.replace(/\s/g, '').length
}

utils.isEmptyObject = (obj) => {
    return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
    )
}

utils.urlJoin = (baseUrl, paths) => {
    // TODO: prevent calls to urlJoin when baseUrl is not set
    if (!baseUrl) {
        // TODO: Figure out a way to catch and handle TypeError exceptions globally
        throw new TypeError('Invalid parameters for urlJoin function.')
    }

    // make paths an array:
    if (typeof paths === 'string') {
        paths = [baseUrl, paths]
    } else {
        paths = [baseUrl, ...paths]
    }

    // remove null values from paths (???)
    paths = paths.filter((path) => path !== null && path !== undefined)

    const hasTrailingSlash = paths.length > 0 && paths[paths.length - 1].endsWith('/')

    // remove '/' from start and end of each path:
    paths = paths
        .map((path) => path.replace(/^\/|\/$/g, ''))
        .filter((path) => path !== '')

    // join all path elements with '/'
    const fullPath =
        (baseUrl.startsWith('http') ? '' : '/') +
        paths.join('/') +
        (hasTrailingSlash ? '/' : '')

    try {
        return new URI(fullPath).toString()
    } catch (e) {
        throw new Error('Invalid parameters for urlJoin function.')
    }
}

utils.val_urls = (conf) => {
    return {
        request: utils.urlJoin(conf.VALUATION_API_URL, 'request'),
        request_ref: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref]),
        request_ref_status: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'status',
            ]),
        request_ref_transaction_value: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'transaction_value',
            ]),
        request_ref_owner: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'owner',
            ]),
        request_ref_valuer: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'valuer',
            ]),
        request_ref_type: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'type',
            ]),
        request_ref_borrower: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'borrower',
            ]),
    }
}

utils.parseJwt = (token) => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

utils.getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts
            .pop()
            .split(';')
            .shift()
    }
    return undefined
}

utils.deleteCookie = (name) => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

utils.formatDate = (aDate, textFormat = false, lang = null) => {
    if (typeof aDate === 'string') aDate = new Date(aDate)

    if (textFormat) {
        return aDate.toLocaleString([lang], {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    } else {
        return `${('0' + aDate.getDate()).slice(-2)}/${(
            '0' +
            (aDate.getMonth() + 1)
        ).slice(-2)}/${aDate.getFullYear()}`
    }
}

utils.formatTime = (aDate, lang = null) => {
    if (typeof aDate === 'string') aDate = new Date(aDate)
    return aDate.toLocaleString([lang], {
        hour: 'numeric',
        minute: 'numeric',
    })
}

utils.formatDateString = (date) => {
    const months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ]
    const month = 'global.shared.' + months[date.getMonth()]

    const day = date.getDate()

    return [day, month]
}

utils.parseFUDate = (dateStr) => {
    if (dateStr === null || dateStr === undefined) return null
    // If dateStr is in iso format, just pass it to the Date constructor
    if (dateStr.includes('T')) return new Date(dateStr)
    // "Weird, everything used to work, back in october, november and december…"
    const parts = dateStr.split(' ')
    const dateParts = parts[0].split('-')
    const timeParts = parts[1].split(':')

    const year = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1 // JavaScript counts months from 0
    const day = parseInt(dateParts[2], 10)
    const hour = parseInt(timeParts[0], 10)
    const minute = parseInt(timeParts[1], 10)
    const second = parseInt(timeParts[2], 10)

    return new Date(year, month, day, hour, minute, second)
}

utils.arrayToggle = (array, value) => {
    var index = array.indexOf(value)

    if (index === -1) {
        array.push(value)
    } else {
        array.splice(index, 1)
    }
}

//https://stackoverflow.com/a/37164538
utils.isObject = (item) => item && typeof item === 'object' && !Array.isArray(item)

utils.mergeDeep = (target, source) => {
    let output = Object.assign({}, target)
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] })
                else output[key] = mergeDeep(target[key], source[key])
            } else {
                Object.assign(output, { [key]: source[key] })
            }
        })
    }
    return output
}

utils.numberWithSpaces = (x) => {
    return x.toLocaleString('fr-BE')
}

utils.numberWithPoints = (x) => {
    return x.toLocaleString('de-DE')
}

utils.priceFormat = (x, thousands = true, lang = i18n.locale) => {
    // Non-breakable space is char 0xa0 (160 dec)
    const roundedValue = 
        Math.round(x / (thousands ? 1000 : 10)) * (thousands ? 1000 : 10)
    
    const formater = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    }).format
    return formater(roundedValue)

}

utils.formatTime = (date) => {
    const formatNumber = (num) => {
        return num.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })
    }

    return `${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}`
}

utils.random_string = (length) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    let values = new Uint32Array(length)
    window.crypto.getRandomValues(values)
    for (let i = 0; i < length; i++) {
        result += charset[values[i] % charset.length]
    }
    return result
}

// https://stackoverflow.com/a/3464346 but modified because it made some mistakes or didn't conform to what we're trying to achieve
utils.dateDiffInBusinessDays = (dDate1, dDate2) => {
    var iWeeks,
        iDateDiff,
        iAdjust = 0
    if (dDate2 < dDate1) return -1 // error code if dates transposed
    var iWeekday1 = dDate1.getDay() // day of week
    var iWeekday2 = dDate2.getDay()
    iWeekday1 = iWeekday1 == 0 ? 7 : iWeekday1 // change Sunday from 0 to 7
    iWeekday2 = iWeekday2 == 0 ? 7 : iWeekday2
    if (iWeekday1 > 5) iAdjust = 1
    iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1 // only count weekdays
    iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2

    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)

    if (iWeekday1 <= iWeekday2) {
        iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1)
    } else {
        iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2)
    }

    iDateDiff -= iAdjust

    return iDateDiff
}

utils.dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

    return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

utils.generateRequestId = (onlyNums = true) => {
    return Math.random()
        .toString(onlyNums ? 10 : 36)
        .slice(2)
}

// https://stackoverflow.com/a/60783784
utils.generateIntervalsOf = (interval, start, end, endIncluded = false) => {
    const result = []
    let current = start

    while (current < end) {
        result.push(current)
        current += interval
    }

    return result
}

utils.formatAddress = ({
    streetname,
    streetnumber,
    postalcode,
    municipality,
    boxnumber,
}) => {
    const box_appendix = boxnumber ? ` b ${boxnumber}` : ''
    return {
        firstLine: `${streetname} ${streetnumber}${box_appendix}`,
        secondLine: `${postalcode} ${municipality}`,
    }
}

utils.full_address = (address, features) => {
    if (!address) return ''
    let building_type = features ? features.f_building_type : null
    if (building_type === 'house' || building_type === 'apartment' || !building_type) {
        if (!address.streetname) return ''
        let box_appendix = address.boxnumber ? ` b ${address.boxnumber}` : ''
        if (address.postalcode !== null) {
            return `${address.streetname} ${address.streetnumber}${box_appendix}, ${address.postalcode} ${address.municipality}`
        }
    } else {
        let parcel_ids = features ? features.parcel_ids : null
        if (parcel_ids) {
            return parcel_ids[0]
        }
    }
    return ''
}

utils.via_address = (address, features) => {
    if (!address) return ''
    let building_type = features ? features.f_building_type : null
    if (
        building_type !== 'house' &&
        building_type !== 'apartment' &&
        building_type !== null
    ) {
        return utils.full_address(address)
    }
    return ''
}

utils.short_address = (address, features) => {
    let building_type = features ? features.f_building_type : null
    if (building_type === 'house' || building_type === 'apartment' || !building_type) {
        let box_appendix = address.boxnumber ? ` b ${address.boxnumber}` : ''
        if (address.postalcode !== null) {
            return `${address.streetname} ${address.streetnumber}${box_appendix}, ${address.postalcode}`
        }
    } else {
        let parcel_ids = features ? features.parcel_ids : null
        if (parcel_ids) {
            return parcel_ids[0]
        }
    }
    return ''
}

//https://dev.to/gladchinda/javascript-tip-whatis-a-better-typeof-3g0o
utils.whatis = (value) => {
    return Object.prototype.toString
        .call(value)
        .replace(/^\[object\s+([a-z]+)\]$/i, '$1')
        .toLowerCase()
}

utils.is_apartment = (f_building_type) =>
    ['apartment', 'new_apartment'].includes(f_building_type)
utils.is_house = (f_building_type) => ['house', 'new_house'].includes(f_building_type)
utils.is_building = (f_building_type) =>
    utils.is_house(f_building_type) || utils.is_apartment(f_building_type)
utils.is_new = (f_building_type) =>
    ['new_house', 'new_apartment'].includes(f_building_type)
utils.is_plot = (f_building_type) => ['construction_plot'].includes(f_building_type)

utils.forced_sale_value = (value) => {
    const x = value / 1000000
    let y
    if (x < 0.2) {
        y = 1 - x
    } else if (x < 0.4) {
        y = 0.9 - x / 2
    } else {
        y = 0.8 - x / 4
    }
    // Above lines are the best way we found to replace numpy.interp for more than just one segment
    // x vector is [100000, 200000, 400000, 800000]
    // y vector is [0.9, 0.8, 0.7, 0.6]
    // We divide by 1MIL before doing operations to avoid having too many zeroes lying around

    return Math.round((y * value) / 1000) * 1000
}

utils.rolesToList = (userRoles) => {
    const rolesList = []
    for (const [mod, roles] of Object.entries(userRoles)) {
        for (const role of roles) {
            rolesList.push(`${mod}:${role}`)
        }
    }
    rolesList.sort()
    return rolesList
}

utils.listToRoles = (rolesList) => {
    const userRoles = {}
    for (const userRole of rolesList) {
        const [mod, role] = userRole.split(':')
        if (mod in userRoles) {
            userRoles[mod].push(role)
        } else {
            userRoles[mod] = [role]
        }
    }
    return userRoles
}

utils.containsRole = (rolesObj, roleOrList, module) => {
    // Can take a list of roles, or a list of [role, module] pairs

    if (typeof roleOrList !== 'string')
        return roleOrList.some((r) =>
            typeof r === 'string'
                ? utils.containsRole(rolesObj, r, module)
                : utils.containsRole(rolesObj, r[0], r[1])
        )

    const role = roleOrList

    if (!module) {
        for (const roles of Object.values(rolesObj)) {
            if (roles.includes(role)) {
                return true
            }
        }
        return false
    }

    return rolesObj['*'].includes(role) || rolesObj[module]?.includes(role)
}

utils.uuidv4 = () =>
    '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
            16
        )
    )

utils.isMobile = () => {
    return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        ) || window.innerWidth < 1000
    )
}

export default utils
