<script>
import Report from '@/../../Monolith/src/apps/Valuation/components/report/BuildingReport.vue'

export default {
    components: {
        Report,
    },
}
</script>

<template>
    <div id="report">
        <Report />
    </div>
</template>

<style lang="scss" scoped>
#report {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
</style>
