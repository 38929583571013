<i18n>
{
  "nl-BE": {
    "show_info": "Toon gebouwinformatie"
  },
  "en-BE": {
    "show_info": "Show building information"
  },
  "fr-BE": {
    "show_info": "Montrer les informations du bâtiment"
  }
}
</i18n>

<template>
    <div id="house">
        <sidebar
            :house="getFullValuationStore"
            :class="{
                active: sidebarOpen,
                animate: !sidebarOpen && sidebarAnimated,
                hidden: !sidebarAnimated,
            }"
            :closable="sidebarOpen || sidebarAnimated"
            @close="closeSidebar"
        />
        <div class="house-content">
            <div class="controls py-3">
                <div class="buttons has-addons">
                    <div
                        class="button is-primary"
                        :class="{
                            'is-inverted': $route.params.action !== '3d',
                        }"
                        :disabled="getFeatures.level !== '3'"
                        @click="
                            $router.push({
                                name: 'action',
                                params: {
                                    lang: $route.params.lang,
                                    action: '3d',
                                },
                                query: $route.query,
                            })
                        "
                    >
                        3D
                    </div>
                    <div
                        class="button is-primary"
                        :class="{ 'is-inverted': $route.params.action !== '2d' }"
                        @click="
                            $router.push({
                                name: 'action',
                                params: {
                                    lang: $route.params.lang,
                                    action: '2d',
                                },
                                query: $route.query,
                            })
                        "
                    >
                        2D
                    </div>
                    <div
                        class="button is-primary"
                        :class="{ 'is-inverted': $route.params.action !== 'streetview' }"
                        :disabled="!getView.gsv_available"
                        @click="
                            $router.push({
                                name: 'action',
                                params: {
                                    lang: $route.params.lang,
                                    action: 'streetview',
                                },
                                query: $route.query,
                            })
                        "
                    >
                        Streetview
                    </div>
                </div>
                <div class="control-plane control-plane-buttons">
                    <div
                        class="panel-button"
                        :class="{ active: $route.params.action === '3d' }"
                        @click="
                            $router.push({
                                name: 'action',
                                params: {
                                    lang: $route.params.lang,
                                    action: '3d',
                                },
                                query: $route.query,
                            })
                        "
                    >
                        3D
                    </div>
                    <div
                        class="panel-button"
                        :class="{ active: $route.params.action === '2d' }"
                        @click="
                            $router.push({
                                name: 'action',
                                params: {
                                    lang: $route.params.lang,
                                    action: '2d',
                                },
                                query: $route.query,
                            })
                        "
                    >
                        2D
                    </div>
                    <div
                        class="panel-button"
                        :class="{ active: $route.params.action === 'streetview' }"
                        @click="
                            $router.push({
                                name: 'action',
                                params: {
                                    lang: $route.params.lang,
                                    action: 'streetview',
                                },
                                query: $route.query,
                            })
                        "
                    >
                        Streetview
                    </div>
                </div>
            </div>
            <div
                v-if="$route.params.action === '3d'"
                id="map3d"
                style="width: 100%; height: 100%"
            >
                <renderer
                    :building-id="getFeatures.building_id"
                    :solar-potential="false"
                    :map-scene3d="mapScene3d"
                    @update:mapScene3d="mapScene3d = $event"
                ></renderer>
            </div>
            <div v-if="$route.params.action === '2d'" id="map2d">
                <re-map
                    :building-id="getFeatures.building_id"
                    :parcel-ids="getFeatures.parcel_ids"
                    :level="getFeatures.level"
                    :read-only="false"
                    :map-scene2d="getFullValuationStore.map_scene2d"
                    :focus-layer="'building'"
                    :center="{ lat: getFeatures.f_lat, lng: getFeatures.f_lng }"
                    :lang="$route.params.lang"
                    @update:buildingId="changeBuildingId($event)"
                    @update:parcelIds="UPDATE_FEATURES({ parcel_ids: $event })"
                    @update:mapScene2d="UPDATE_MAP_SCENE2D($event)"
                ></re-map>
            </div>
            <div
                v-if="$route.params.action === 'streetview' && getView.gsv_available"
                id="street"
            >
                <div
                    class="small-map"
                    style="width: 300px; height: 300px; position: absolute; top: 12px; left: 12px"
                >
                    <re-map
                        :building-id="getFeatures.building_id"
                        :parcel-ids="getFeatures.parcel_ids"
                        :level="getFeatures.level"
                        :read-only="false"
                        :map-scene2d="getFullValuationStore.map_scene2d"
                        :focus-layer="'building'"
                        :center="{ lat: getView.gsv_lat, lng: getView.gsv_lng }"
                        :layers-control-visible="false"
                        @update:buildingId="changeBuildingId($event)"
                        @update:parcelIds="UPDATE_FEATURES({ parcel_ids: $event })"
                        @update:mapScene2d="UPDATE_MAP_SCENE2D($event)"
                    >
                        <template slot="custom-layer">
                            <rotating-marker
                                v-if="getView.gsv_lat != null"
                                :lat-lng="[getView.gsv_lat, getView.gsv_lng]"
                                :heading="getView.gsv_ph"
                                :scale-y="getView.gsv_pz"
                                :icon="icon"
                            ></rotating-marker>
                        </template>
                    </re-map>
                </div>
                <GmapStreetViewPanorama
                    style="width: 100%; height: 100%"
                    class="pano"
                    :motion-tracking="true"
                    :pov="{
                        heading: getView.gsv_init_ph || 0,
                        pitch: getView.gsv_init_pp || 0,
                    }"
                    :zoom="getView.gsv_init_pz || 1"
                    :pano="getView.gsv_p"
                    :options="{
                        fullscreenControl: false,
                        addressControl: false,
                    }"
                    @position_changed="
                        UPDATE_VIEW({ gsv_lat: $event.lat(), gsv_lng: $event.lng() })
                    "
                    @pano_changed="UPDATE_VIEW({ gsv_p: $event })"
                    @pov_changed="
                        UPDATE_VIEW({
                            gsv_pp: $event.pitch,
                            gsv_ph: $event.heading,
                            gsv_pz: $event.zoom,
                        })
                    "
                ></GmapStreetViewPanorama>
            </div>
        </div>
        <div class="mobile-bottom-menu">
            <div class="bottom-menu-button" @click="openSidebar">
                {{ $t('show_info') }}
            </div>
        </div>
    </div>
</template>

<script>
import ReMap from './../../../Monolith/src/components/common/Map.vue'
import Sidebar from './../components/HouseSidebar'
import Renderer from '@/shared/components/3DComponents/Renderer3D.vue'
import RotatingMarker from './../../../Monolith/src/components/common/RotatingMarker.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { icon } from 'leaflet'

export default {
    name: 'House',
    components: {
        ReMap,
        sidebar: Sidebar,
        renderer: Renderer,
        RotatingMarker,
    },
    data() {
        return {
            // height: 400,
            sidebarOpen: false,
            sidebarAnimated: false,
            map_zoom: 18,
            tile_url:
                'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia3ZhbmxvbWJlZWsiLCJhIjoiY2l4cnVtd3VuMDAzYjMwcGJnY3Fma2VrMyJ9.hQdHMbUXYGgrFnQlCunUnQ',
            mapScene2d: {
                building_geojson: null,
                parcel_geojson: null,
                address_geojson: null,
                swimming_pool_geojson: null,
                flood_geojson: null,
            },
            mapScene3d: {
                building_id: null,
            },
            pano: null,
            icon: icon({
                iconUrl: `${process.env.VUE_APP_FRONTEND_URL.replace(
                    /\/$/,
                    ''
                )}/sector.svg`,
                iconSize: [80, 40],
                iconAnchor: [40, 40],
            }),

            /*  house: {
        address: "Kleine Schuurstraat 1 9040 Sint-Amandsberg (Gent)",
        coordinates: { lat: 51.0728886, lng: 3.7738913 },
      }, */
        }
    },
    computed: {
        ...mapGetters(['getFullValuationStore', 'getFeatures', 'getView']),
    },
    created() {
        // Get building features. If the features are correctly returned, the 2D map can be drawn.
        // this.height = window.innerHeight - 52
        // window.addEventListener('resize', () => {
        //     this.height = window.innerHeight - 52
        // })
    },
    methods: {
        ...mapMutations([
            'UPDATE_FEATURES',
            'UPDATE_MAP_SCENE2D',
            'UPDATE_VIEW',
            'UPDATE_ADDRESS',
        ]),
        ...mapActions(['updateBuildingId']),
        // The style of the buildings and parcels are functions. The parcel and building polygon for the house
        // have thicker boundaries
        style_buildings(feature) {
            return {
                color: '#08519c',
                fillColor: '#6baed6',
                weight: feature.properties.target ? 2 : 0.75,
                opacity: 1,
                fillOpacity: feature.properties.target ? 1 : 0.4,
            }
        },
        style_parcels(feature) {
            return {
                color: '#1abc9c',
                fillColor: '#6baed6',
                weight: 0.5,
                opacity: 1,
                fillOpacity: 0.1,
            }
        },
        style_swimming_pools(feature) {
            return {
                color: '#003399',
                fillColor: '#0044cc',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            }
        },
        style_flood(feature) {
            return {
                color: '#003399',
                fillColor: '#0044cc',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            }
        },
        goBack() {
            this.$router.push({ path: '/' })
        },
        closeSidebar() {
            this.sidebarAnimated = true
            this.sidebarOpen = false
            setTimeout(() => {
                this.sidebarAnimated = false
            }, 500)
        },
        openSidebar() {
            this.sidebarOpen = true
            this.sidebarAnimated = true
        },
        changeBuildingId(building_id) {
            let query = { ...this.$route.query }
            query.building_id = building_id
            delete query.streetname
            delete query.streetnumber
            delete query.postalcode
            delete query.municipality

            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: query,
            })
            this.UPDATE_ADDRESS({
                streetname: null,
                streetnumber: null,
                postalcode: null,
                municipality: null,
            })
            this.updateBuildingId(building_id)
        },
    },
}
</script>

<style lang="scss" scoped>
#house {
    overflow: hidden;
    position: relative;
}
</style>
