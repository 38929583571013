var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card",attrs:{"id":"toc"}},[_c('div',{staticClass:"card-content content is-small has-text-centered"},[_c('div',{staticClass:"toc_actions"},[_c('b-button',{attrs:{"size":"is-normal","type":"is-primary","icon-left":"close","tag":"router-link","to":{
                        name: 'action',
                        params: {
                            action: '2d',
                            lang: _vm.$route.params.lang,
                        },
                        query: _vm.$route.query,
                    }}}),_c('b-button',{attrs:{"size":"is-normal","type":"is-primary","icon-left":"download","tag":"a","href":_vm.reportURL}})],1)])]),_c('div',{staticClass:"has-text-centered is-size-7",attrs:{"id":"report-vue"}},[_c('div',{staticClass:"page is-flex is-flex-direction-column",attrs:{"id":"page-general"}},[_c('div',{staticClass:"section content pt-5 px-5 pb-2"},[_c('div',{staticClass:"columns is-multiline has-text-left"},[_vm._m(0),_c('div',{staticClass:"column is-6 has-text-right is-size-6 pb-0"},[_c('div',{staticClass:"has-text-weight-bold has-text-black"},[_vm._v(" "+_vm._s(_vm.$t('front.core_title'))+" ")])]),_c('div',{staticClass:"column is-6 py-2"},[_c('p',{staticClass:"is-size-7 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('address.full_address'))+" ")]),_c('p',{staticClass:"has-text-weight-bold has-text-black is-size-7"},[_vm._v(" "+_vm._s(_vm.getAddress.full_address)+" ")])]),_c('div',{staticClass:"column is-3 py-2"},[_c('p',{staticClass:"is-size-7 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('export.by'))+" ")]),_c('p',{staticClass:"has-text-weight-bold has-text-black is-size-7"},[_vm._v(" "+_vm._s(_vm.$route.query.email || _vm.getFullStore.auth.email)+" ")])]),_c('div',{staticClass:"column is-3 py-2"},[_c('p',{staticClass:"is-size-7 mb-0"},[_vm._v(_vm._s(_vm.$t('export.date')))]),_c('p',{staticClass:"has-text-weight-bold has-text-black is-size-7"},[_vm._v(" "+_vm._s(new Intl.DateTimeFormat('fr-BE', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', /*second: 'numeric',*/ timeZone: 'Europe/Brussels', }).format(new Date()))+" ")])])]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"image is-square mb-2"},[(_vm.getFeatures.f_lat)?_c('GmapStreetViewPanorama',{staticClass:"pano has-ratio",staticStyle:{"width":"100%","height":"100%"},attrs:{"position":{
                                    lat: _vm.getFeatures.f_lat,
                                    lng: _vm.getFeatures.f_lng,
                                },"options":{
                                    disableDefaultUI: true,
                                    fullscreenControl: false,
                                    addressControl: false,
                                }}}):_vm._e()],1),_c('div',{staticClass:"image is-square my-2"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"auto-select-parcels":true,"level":_vm.getFeatures.level,"map-scene2d":_vm.getFullValuationStore.map_scene2d,"center":{
                                    lat: _vm.getFeatures.f_lat,
                                    lng: _vm.getFeatures.f_lng,
                                },"read-only":true,"focus-layer":'building',"size":'small',"layers-control-visible":false,"region":_vm.getFeatures.region,"flood-visible":false,"options":{
                                    zoomControl: false,
                                    attributionControl: false,
                                    scrollWheelZoom: false,
                                },"lang":_vm.$route.params.lang},on:{"update:parcelIds":function($event){return _vm.UPDATE_FEATURES({ parcel_ids: $event })},"update:mapScene2d":function($event){return _vm.UPDATE_MAP_SCENE2D($event)}}})],1),_c('div',{staticClass:"image is-square my-2"},[_c('re-map',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"parcel-ids":_vm.getFeatures.parcel_ids,"auto-select-parcels":false,"level":_vm.getFeatures.level,"region":_vm.getFeatures.region,"map-scene2d":_vm.getFullValuationStore.map_scene2d,"center":{
                                    lat: _vm.getFeatures.f_lat,
                                    lng: _vm.getFeatures.f_lng,
                                },"read-only":true,"focus-layer":'building',"size":'small',"layers-control-visible":false,"options":{
                                    zoomControl: false,
                                    attributionControl: false,
                                    scrollWheelZoom: false,
                                },"aerial-tile-visible":false,"cadastral-tile-visible":false}})],1),_c('div',{staticClass:"image is-3by2 mt-2"},[(_vm.getFeatures.level === '3')?_c('renderer',{staticClass:"has-ratio",attrs:{"building-id":_vm.getFeatures.building_id,"show-attributions":false,"auto-rotate":_vm.$route.query.static !== 'true',"map-scene3d":_vm.mapScene3d},on:{"update:mapScene3d":function($event){_vm.mapScene3d = $event}}}):_c('div',{staticClass:"card has-ratio"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('3d model unavailable'))+" ")])])])],1)]),_c('div',{staticClass:"column is-4"},[_c('report-section',{attrs:{"name":'address_location'}}),_c('report-section',{attrs:{"name":'c_building'}}),_c('report-section',{attrs:{"name":'secondary_buildings'}}),_c('report-section',{attrs:{"name":'flood'}})],1),_c('div',{staticClass:"column is-4"},[_c('report-section',{attrs:{"name":'c_parcels'}}),_c('report-section',{attrs:{"name":'reconstruction_value'}}),_c('report-section',{attrs:{"name":'economic_activity'}}),_c('report-section',{attrs:{"name":'roof'}})],1)])]),_c('report-footer')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-6 has-text-left pb-0"},[_c('img',{attrs:{"width":"150px","src":require("@/shared/assets/icons/rockestate-logo-horizontal-dark.svg")}})])
}]

export { render, staticRenderFns }